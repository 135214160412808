import "./about_us.scss";
import React from 'react'

function Faq() {
  return (
    <div style={{padding: '20px'}}>
      
      <h2>FAQ</h2>
      <br></br>
              <h2>Frequently Asked Questions</h2>
              <br></br>

<p><strong>General</strong></p>
              <br></br>

<p><strong>What is your platform about?</strong></p>
<p>Our platform brings together social media influencers and their fans in one place. It provides a centralized space for fans to connect with their favorite influencers, stay updated on their latest content, and engage with other like-minded fans.</p>
              <br></br>

<p><strong>How can I join the platform as a fan?</strong></p>
<p>To join our platform as a fan, simply visit our website and click on the "Sign Up" button. Fill out the registration form with your details and create an account. Once registered, you'll have access to the platform and can start connecting with influencers and fellow fans for a lifetime.</p>
              <br></br>

<p><strong>How can I become an influencer on your platform?</strong></p>
<p>If you're an influencer and want to join our platform, we'd love to have you! Please visit our website and navigate to the "Sign In" page. Invite your fans to join you here from all other social media platforms. Fill out the required information, including your social media handles, follower count, and areas of expertise.</p>
              <br></br>

<p><strong>What benefits do influencers get from our platform?</strong></p>
<p><strong>Influencers Welcome:</strong></p>
              <br></br>

<ul>
  <li>🤝Revitalized Fanbase: Regain and reconnect with fans after social media setbacks</li>
  <li>🌟 Connect for Life: Create a bond that lasts! Engage with your fans like never before, building a community that stands the test of time.</li>
  <li>📢 Seize Opportunities: Step into a world of possibilities! Partner with diverse advertisers and turn your influence into a gateway for exciting ventures.</li>
  <li>🔗 Join Forces: Merge your passion with unparalleled opportunities. Join our platform and bridge the gap between your fans and the brands you love.</li>
  <li>💡 Where Influence Thrives: Your journey to influence, elevated. Our platform nurtures your connections, fuels your growth, and paves the way for endless achievements.</li>
  <li>🌐 Beyond Boundaries: Break free from limits! Reach audiences worldwide, collaborate with global brands, and watch your influence transcend horizons.</li>
  <li>🎉 Ignite Your Impact: Spark a revolution with every post! Elevate your status, fuel your potential, and let your influence reshape the digital realm.</li>
  <li>🚀 Your Influence, Your Legacy: It's more than just a post; it's your legacy. Craft a lasting impact with your fans and advertisers, etching your name in the halls of influence.</li>
</ul>
              <br></br>


<p><strong>Why should you connect with us?</strong></p>
<p><strong>For Fans Who Dream:</strong></p>
              <br></br>

<ul>
  <li>🌟 Your Star-Studded Connection: Forge a bond that transcends screens! Engage with your favorite stars like never before, creating memories that last a lifetime.</li>
  <li>🌈 Unveil the Magic: Step into the enchanting realm of direct connections. Interact with your idols, sharing moments that become cherished stories.</li>
  <li>👑 Your VIP Access: Gain an exclusive pass to the world of your stars. Experience their journey up close and personal, forming a connection that stands the test of time.</li>
  <li>💬 Conversations to Remember: From virtual exchanges to shared experiences, relish interactions that transform fandom into a lifelong companionship.</li>
  <li>💫 Beyond the Screen: The screen fades, but the connection remains. Dive into a realm where stars become friends and dreams are nurtured into reality.</li>
  <li>🌐 Where Fandom Thrives: Join our platform and witness the evolution of fandom. Immerse yourself in a community where star-fan relationships flourish.</li>
  <li>🌌 Infinite Possibilities: Unlock doors you never thought possible. From virtual events to personalized messages, immerse yourself in a world of limitless possibilities.</li>
  <li>🎉 Your Fan Journey, Elevated: Elevate your fandom experience from passive to participatory. Be a part of your star's world like never before.</li>
  <li>🚀 Embark on this journey now, and create memories, moments, and connections that define a lifetime of fandom bliss!</li>
</ul>
              <br></br>

<p><strong>How can I connect with my favorite influencers on your platform?</strong></p>
<p>Once you're a registered fan on our platform, you can search for your favorite influencers using our search feature. Explore their profiles, follow them, and interact with their content through comments. You'll also have access to exclusive content and updates from the influencers you follow.</p>
              <br></br>

<p><strong>Can I interact with other fans on the platform?</strong></p>
<p>Absolutely! Our platform encourages fan interaction and community building. You can join discussion forums, participate in fan groups, and connect with other fans who share similar interests. Engage in conversations, exchange ideas, and make new friends within the fan community.</p>
              <br></br>


       </div>
  )
}

export default Faq