import "./share.scss";
import Image from "../../assets/img.png";
import Map from "../../assets/map.png";
import Friend from "../../assets/friend.png";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/authContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { toast } from "react-toastify";
import { CloudUpload, Email } from "@mui/icons-material";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const UpdateProfile = () => {
    const [file, setFile] = useState(null);
    const [profile, setProfile] = useState("");
    const [texts, setTexts] = useState({
        first_name: "",
        email: "",
        country: "",
        state: "",
        city: "",
        gender: "",
        zip: "",
        date_of_birth: "",
        graduation: "",
        alternate_contact: "",
        yealry_salary: "",
        employed: ""
    });


    const handleChange = (e) => {
        setTexts((prev) => ({ ...prev, [e.target.name]: [e.target.value] }));
    };

    const { currentUser } = useContext(AuthContext);
    const queryClient = useQueryClient();

    console.log(currentUser);




    const mutation = useMutation(
        (maindata) => {
            return makeRequest.post("/user/update/" + currentUser.data.user.id, maindata);
        },
        {
            onSuccess: () => {
                // Invalidate and refetch
                queryClient.invalidateQueries(["user"]);
                makeRequest.get("/user/" + currentUser.data.user.id).then((res) => {
                    setdata(res.data.data.user);
                    setTexts({
                        first_name: res.data.data.user.first_name,
                        email: res.data.data.user.email,
                        country: res.data.data.user.country,
                        state: res.data.data.user.state,
                        city: res.data.data.user.city,
                        gender: res.data.data.user.gender,
                        zip: res.data.data.user.zip,
                        date_of_birth: res.data.data.user.date_of_birth,
                        graduation: res.data.data.user.graduation,
                        alternate_contact: res.data.data.user.alternate_contact,
                        yealry_salary: res.data.data.user.yealry_salary,
                        employed: res.data.data.user.employed,
                    });
                    setFile(res.data.data.user.image);

                })
                setProfile("");
                toast.success("updated successfully..!", { delay: 500 });
            },
            onError: (error) => {
                toast.error(error.response.data.message, { delay: 500 });
            }
        }
    );
    const [data, setdata] = useState([]);
    useEffect(() => {
        makeRequest.get("/user/" + currentUser.data.user.id).then((res) => {
            setdata(res.data.data.user);
            setTexts({
                first_name: res.data.data.user.first_name,
                email: res.data.data.user.email,
                country: res.data.data.user.country,
                state: res.data.data.user.state,
                city: res.data.data.user.city,
                gender: res.data.data.user.gender,
                zip: res.data.data.user.zip,
                date_of_birth: res.data.data.user.date_of_birth,
                graduation: res.data.data.user.graduation,
                alternate_contact: res.data.data.user.alternate_contact,
                yealry_salary: res.data.data.user.yealry_salary,
                employed: res.data.data.user.employed,
            });
            setFile(res.data.data.user.image);

        })
    }, [])

    const handleClick = async (e) => {
        e.preventDefault();




        //TODO: find a better way to get image URL

        const formdata = new FormData();
        formdata.append("image", profile);
        formdata.append("first_name", texts.first_name);
        formdata.append("email", texts.email);
        formdata.append("country", texts.country);
        formdata.append("state", texts.state);
        formdata.append("city", texts.city);
        formdata.append("gender", texts.gender);
        formdata.append("zip", texts.zip);
        formdata.append("date_of_birth", texts.date_of_birth);
        formdata.append("graduation", texts.graduation);
        formdata.append("alternate_contact", texts.alternate_contact);
        formdata.append("yealry_salary", texts.yealry_salary);
        formdata.append("employed", texts.employed);


        mutation.mutate(formdata);


    };

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    return (
        <div className="share">
            <div className="container">
                <div className="top">
                    <Box sx={{ width: '100%' }}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12}>
                                <div className="files">
                                    <label htmlFor="profile">
                                        <span>Profile Picture</span>
                                        <div className="imgContainer">
                                            <img
                                                src={file !== "" ? file : "https://img.freepik.com/premium-vector/young-smiling-man-avatar-man-with-brown-beard-mustache-hair-wearing-yellow-sweater-sweatshirt-3d-vector-people-character-illustration-cartoon-minimal-style_365941-860.jpg?w=2000"}
                                                alt=""
                                            />
                                            <CloudUpload className="icon" />
                                        </div>
                                    </label>
                                    <input
                                        type="file"
                                        id="profile"
                                        style={{ display: "none" }}
                                        onChange={(e) => setProfile(e.target.files[0])}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <input
                                    type="text"
                                    value={texts.first_name !== null ? texts.first_name : "-"}
                                    style={{ width: '100%', padding: '10px 0px', marginBottom: '20px', fontSize: '17px', outline: 'none', border: 'none', borderBottom: '1px solid #ccc' }}
                                    name="first_name"
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <input
                                    type="text"
                                    value={texts.email !== null ? texts.email : "-"}
                                    style={{ width: '100%', padding: '10px 0px', marginBottom: '20px', fontSize: '17px', outline: 'none', border: 'none', borderBottom: '1px solid #ccc' }}
                                    name="email"
                                    onChange={handleChange}
                                />
                            </Grid>
                            
                            <Grid item xs={6}>
                                <label>State</label>
                                <input
                                    type="text"
                                    value={texts.state !== "null" ? texts.state : "-"}
                                    placeholder="State"
                                    style={{ width: '100%', padding: '10px 0px', marginBottom: '20px', fontSize: '17px', outline: 'none', border: 'none', borderBottom: '1px solid #ccc' }}
                                    name="state"
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <label>City</label>
                                <input
                                    type="text"
                                    value={texts.city !== "null" ? texts.city : "-"}
                                    style={{ width: '100%', padding: '10px 0px', marginBottom: '20px', fontSize: '17px', outline: 'none', border: 'none', borderBottom: '1px solid #ccc' }}
                                    name="city"
                                    placeholder="City"
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <label>Pin Code</label>
                                <input
                                    type="text"
                                    placeholder="Zip"
                                    value={texts.zip !== "null" ? texts.zip : "-"}
                                    style={{ width: '100%', padding: '10px 0px', marginBottom: '20px', fontSize: '17px', outline: 'none', border: 'none', borderBottom: '1px solid #ccc' }}
                                    name="zip"
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <label>Date of birth</label>
                                <input
                                    type="date"
                                    value={texts.date_of_birth !== null ? texts.date_of_birth : "-"}
                                    style={{ width: '100%', padding: '10px 0px', marginBottom: '20px', fontSize: '17px', outline: 'none', border: 'none', borderBottom: '1px solid #ccc' }}
                                    name="date_of_birth"
                                    placeholder="Date Of Birth"
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <label>Contact</label>
                                <input
                                    type="text"
                                    value={texts.alternate_contact !== null ? texts.alternate_contact : "-"}
                                    style={{ width: '100%', padding: '10px 0px', marginBottom: '20px', fontSize: '17px', outline: 'none', border: 'none', borderBottom: '1px solid #ccc' }}
                                    name="alternate_contact"
                                    placeholder="Alternate Contact"
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <label>Country</label>
                                <select className="form-select" id="country" value={texts.country} onChange={handleChange} name="country">
                                    <option>select country</option>
                                    <option value="AF">Afghanistan</option>
                                    <option value="AX">Aland Islands</option>
                                    <option value="AL">Albania</option>
                                    <option value="DZ">Algeria</option>
                                    <option value="AS">American Samoa</option>
                                    <option value="AD">Andorra</option>
                                    <option value="AO">Angola</option>
                                    <option value="AI">Anguilla</option>
                                    <option value="AQ">Antarctica</option>
                                    <option value="AG">Antigua and Barbuda</option>
                                    <option value="AR">Argentina</option>
                                    <option value="AM">Armenia</option>
                                    <option value="AW">Aruba</option>
                                    <option value="AU">Australia</option>
                                    <option value="AT">Austria</option>
                                    <option value="AZ">Azerbaijan</option>
                                    <option value="BS">Bahamas</option>
                                    <option value="BH">Bahrain</option>
                                    <option value="BD">Bangladesh</option>
                                    <option value="BB">Barbados</option>
                                    <option value="BY">Belarus</option>
                                    <option value="BE">Belgium</option>
                                    <option value="BZ">Belize</option>
                                    <option value="BJ">Benin</option>
                                    <option value="BM">Bermuda</option>
                                    <option value="BT">Bhutan</option>
                                    <option value="BO">Bolivia</option>
                                    <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                    <option value="BA">Bosnia and Herzegovina</option>
                                    <option value="BW">Botswana</option>
                                    <option value="BV">Bouvet Island</option>
                                    <option value="BR">Brazil</option>
                                    <option value="IO">British Indian Ocean Territory</option>
                                    <option value="BN">Brunei Darussalam</option>
                                    <option value="BG">Bulgaria</option>
                                    <option value="BF">Burkina Faso</option>
                                    <option value="BI">Burundi</option>
                                    <option value="KH">Cambodia</option>
                                    <option value="CM">Cameroon</option>
                                    <option value="CA">Canada</option>
                                    <option value="CV">Cape Verde</option>
                                    <option value="KY">Cayman Islands</option>
                                    <option value="CF">Central African Republic</option>
                                    <option value="TD">Chad</option>
                                    <option value="CL">Chile</option>
                                    <option value="CN">China</option>
                                    <option value="CX">Christmas Island</option>
                                    <option value="CC">Cocos (Keeling) Islands</option>
                                    <option value="CO">Colombia</option>
                                    <option value="KM">Comoros</option>
                                    <option value="CG">Congo</option>
                                    <option value="CD">Congo, Democratic Republic of the Congo</option>
                                    <option value="CK">Cook Islands</option>
                                    <option value="CR">Costa Rica</option>
                                    <option value="CI">Cote D'Ivoire</option>
                                    <option value="HR">Croatia</option>
                                    <option value="CU">Cuba</option>
                                    <option value="CW">Curacao</option>
                                    <option value="CY">Cyprus</option>
                                    <option value="CZ">Czech Republic</option>
                                    <option value="DK">Denmark</option>
                                    <option value="DJ">Djibouti</option>
                                    <option value="DM">Dominica</option>
                                    <option value="DO">Dominican Republic</option>
                                    <option value="EC">Ecuador</option>
                                    <option value="EG">Egypt</option>
                                    <option value="SV">El Salvador</option>
                                    <option value="GQ">Equatorial Guinea</option>
                                    <option value="ER">Eritrea</option>
                                    <option value="EE">Estonia</option>
                                    <option value="ET">Ethiopia</option>
                                    <option value="FK">Falkland Islands (Malvinas)</option>
                                    <option value="FO">Faroe Islands</option>
                                    <option value="FJ">Fiji</option>
                                    <option value="FI">Finland</option>
                                    <option value="FR">France</option>
                                    <option value="GF">French Guiana</option>
                                    <option value="PF">French Polynesia</option>
                                    <option value="TF">French Southern Territories</option>
                                    <option value="GA">Gabon</option>
                                    <option value="GM">Gambia</option>
                                    <option value="GE">Georgia</option>
                                    <option value="DE">Germany</option>
                                    <option value="GH">Ghana</option>
                                    <option value="GI">Gibraltar</option>
                                    <option value="GR">Greece</option>
                                    <option value="GL">Greenland</option>
                                    <option value="GD">Grenada</option>
                                    <option value="GP">Guadeloupe</option>
                                    <option value="GU">Guam</option>
                                    <option value="GT">Guatemala</option>
                                    <option value="GG">Guernsey</option>
                                    <option value="GN">Guinea</option>
                                    <option value="GW">Guinea-Bissau</option>
                                    <option value="GY">Guyana</option>
                                    <option value="HT">Haiti</option>
                                    <option value="HM">Heard Island and Mcdonald Islands</option>
                                    <option value="VA">Holy See (Vatican City State)</option>
                                    <option value="HN">Honduras</option>
                                    <option value="HK">Hong Kong</option>
                                    <option value="HU">Hungary</option>
                                    <option value="IS">Iceland</option>
                                    <option value="IN">India</option>
                                    <option value="ID">Indonesia</option>
                                    <option value="IR">Iran, Islamic Republic of</option>
                                    <option value="IQ">Iraq</option>
                                    <option value="IE">Ireland</option>
                                    <option value="IM">Isle of Man</option>
                                    <option value="IL">Israel</option>
                                    <option value="IT">Italy</option>
                                    <option value="JM">Jamaica</option>
                                    <option value="JP">Japan</option>
                                    <option value="JE">Jersey</option>
                                    <option value="JO">Jordan</option>
                                    <option value="KZ">Kazakhstan</option>
                                    <option value="KE">Kenya</option>
                                    <option value="KI">Kiribati</option>
                                    <option value="KP">Korea, Democratic People's Republic of</option>
                                    <option value="KR">Korea, Republic of</option>
                                    <option value="XK">Kosovo</option>
                                    <option value="KW">Kuwait</option>
                                    <option value="KG">Kyrgyzstan</option>
                                    <option value="LA">Lao People's Democratic Republic</option>
                                    <option value="LV">Latvia</option>
                                    <option value="LB">Lebanon</option>
                                    <option value="LS">Lesotho</option>
                                    <option value="LR">Liberia</option>
                                    <option value="LY">Libyan Arab Jamahiriya</option>
                                    <option value="LI">Liechtenstein</option>
                                    <option value="LT">Lithuania</option>
                                    <option value="LU">Luxembourg</option>
                                    <option value="MO">Macao</option>
                                    <option value="MK">Macedonia, the Former Yugoslav Republic of</option>
                                    <option value="MG">Madagascar</option>
                                    <option value="MW">Malawi</option>
                                    <option value="MY">Malaysia</option>
                                    <option value="MV">Maldives</option>
                                    <option value="ML">Mali</option>
                                    <option value="MT">Malta</option>
                                    <option value="MH">Marshall Islands</option>
                                    <option value="MQ">Martinique</option>
                                    <option value="MR">Mauritania</option>
                                    <option value="MU">Mauritius</option>
                                    <option value="YT">Mayotte</option>
                                    <option value="MX">Mexico</option>
                                    <option value="FM">Micronesia, Federated States of</option>
                                    <option value="MD">Moldova, Republic of</option>
                                    <option value="MC">Monaco</option>
                                    <option value="MN">Mongolia</option>
                                    <option value="ME">Montenegro</option>
                                    <option value="MS">Montserrat</option>
                                    <option value="MA">Morocco</option>
                                    <option value="MZ">Mozambique</option>
                                    <option value="MM">Myanmar</option>
                                    <option value="NA">Namibia</option>
                                    <option value="NR">Nauru</option>
                                    <option value="NP">Nepal</option>
                                    <option value="NL">Netherlands</option>
                                    <option value="AN">Netherlands Antilles</option>
                                    <option value="NC">New Caledonia</option>
                                    <option value="NZ">New Zealand</option>
                                    <option value="NI">Nicaragua</option>
                                    <option value="NE">Niger</option>
                                    <option value="NG">Nigeria</option>
                                    <option value="NU">Niue</option>
                                    <option value="NF">Norfolk Island</option>
                                    <option value="MP">Northern Mariana Islands</option>
                                    <option value="NO">Norway</option>
                                    <option value="OM">Oman</option>
                                    <option value="PK">Pakistan</option>
                                    <option value="PW">Palau</option>
                                    <option value="PS">Palestinian Territory, Occupied</option>
                                    <option value="PA">Panama</option>
                                    <option value="PG">Papua New Guinea</option>
                                    <option value="PY">Paraguay</option>
                                    <option value="PE">Peru</option>
                                    <option value="PH">Philippines</option>
                                    <option value="PN">Pitcairn</option>
                                    <option value="PL">Poland</option>
                                    <option value="PT">Portugal</option>
                                    <option value="PR">Puerto Rico</option>
                                    <option value="QA">Qatar</option>
                                    <option value="RE">Reunion</option>
                                    <option value="RO">Romania</option>
                                    <option value="RU">Russian Federation</option>
                                    <option value="RW">Rwanda</option>
                                    <option value="BL">Saint Barthelemy</option>
                                    <option value="SH">Saint Helena</option>
                                    <option value="KN">Saint Kitts and Nevis</option>
                                    <option value="LC">Saint Lucia</option>
                                    <option value="MF">Saint Martin</option>
                                    <option value="PM">Saint Pierre and Miquelon</option>
                                    <option value="VC">Saint Vincent and the Grenadines</option>
                                    <option value="WS">Samoa</option>
                                    <option value="SM">San Marino</option>
                                    <option value="ST">Sao Tome and Principe</option>
                                    <option value="SA">Saudi Arabia</option>
                                    <option value="SN">Senegal</option>
                                    <option value="RS">Serbia</option>
                                    <option value="CS">Serbia and Montenegro</option>
                                    <option value="SC">Seychelles</option>
                                    <option value="SL">Sierra Leone</option>
                                    <option value="SG">Singapore</option>
                                    <option value="SX">Sint Maarten</option>
                                    <option value="SK">Slovakia</option>
                                    <option value="SI">Slovenia</option>
                                    <option value="SB">Solomon Islands</option>
                                    <option value="SO">Somalia</option>
                                    <option value="ZA">South Africa</option>
                                    <option value="GS">South Georgia and the South Sandwich Islands</option>
                                    <option value="SS">South Sudan</option>
                                    <option value="ES">Spain</option>
                                    <option value="LK">Sri Lanka</option>
                                    <option value="SD">Sudan</option>
                                    <option value="SR">Suriname</option>
                                    <option value="SJ">Svalbard and Jan Mayen</option>
                                    <option value="SZ">Swaziland</option>
                                    <option value="SE">Sweden</option>
                                    <option value="CH">Switzerland</option>
                                    <option value="SY">Syrian Arab Republic</option>
                                    <option value="TW">Taiwan, Province of China</option>
                                    <option value="TJ">Tajikistan</option>
                                    <option value="TZ">Tanzania, United Republic of</option>
                                    <option value="TH">Thailand</option>
                                    <option value="TL">Timor-Leste</option>
                                    <option value="TG">Togo</option>
                                    <option value="TK">Tokelau</option>
                                    <option value="TO">Tonga</option>
                                    <option value="TT">Trinidad and Tobago</option>
                                    <option value="TN">Tunisia</option>
                                    <option value="TR">Turkey</option>
                                    <option value="TM">Turkmenistan</option>
                                    <option value="TC">Turks and Caicos Islands</option>
                                    <option value="TV">Tuvalu</option>
                                    <option value="UG">Uganda</option>
                                    <option value="UA">Ukraine</option>
                                    <option value="AE">United Arab Emirates</option>
                                    <option value="GB">United Kingdom</option>
                                    <option value="US">United States</option>
                                    <option value="UM">United States Minor Outlying Islands</option>
                                    <option value="UY">Uruguay</option>
                                    <option value="UZ">Uzbekistan</option>
                                    <option value="VU">Vanuatu</option>
                                    <option value="VE">Venezuela</option>
                                    <option value="VN">Viet Nam</option>
                                    <option value="VG">Virgin Islands, British</option>
                                    <option value="VI">Virgin Islands, U.s.</option>
                                    <option value="WF">Wallis and Futuna</option>
                                    <option value="EH">Western Sahara</option>
                                    <option value="YE">Yemen</option>
                                    <option value="ZM">Zambia</option>
                                    <option value="ZW">Zimbabwe</option>
                                </select>
                            </Grid>
                            <Grid item xs={6}>
                                <label>Gender</label>

                                <select className="form-select" id="gender" value={texts.gender} onChange={handleChange} name="gender">
                                    <option>Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Others</option>
                                </select>
                            </Grid>
                            
                            <Grid item xs={6}>
                                <label>Graduation</label>
                                <select className="form-select" id="graduation" value={texts.graduation} onChange={handleChange} name="graduation">
                                    <option>Select Graducation</option>
                                    <option value="Bachelor of Technology (B.Tech)">Bachelor of Technology (B.Tech)</option>
                                    <option value="Bachelor of Engineering (B.E)">Bachelor of Engineering (B.E)</option>
                                    <option value="Bachelor of Science (B.Sc)">Bachelor of Science (B.Sc)</option>
                                    <option value="Bachelor of Arts (B.A)">Bachelor of Arts (B.A)</option>
                                    <option value="Bachelor of Commerce (B.Com)">Bachelor of Commerce (B.Com)</option>
                                    <option value="Bachelor of Business Administration (BBA)">Bachelor of Business Administration (BBA)</option>
                                    <option value="Bachelor of Fine Arts (BFA)">Bachelor of Fine Arts (BFA)</option>
                                    <option value="Bachelor of Architecture (B.Arch)">Bachelor of Architecture (B.Arch)</option>
                                    <option value="Bachelor of Pharmacy (B.Pharm)">Bachelor of Pharmacy (B.Pharm)</option>
                                    <option value="Bachelor of Laws (LL.B)">Bachelor of Laws (LL.B)</option>
                                    <option value="Bachelor of Medicine, Bachelor of Surgery (MBBS)">Bachelor of Medicine, Bachelor of Surgery (MBBS)</option>
                                    <option value="Other">Other</option>
                                </select>
                            </Grid>
                          
                            <Grid item xs={6}>
                                <label>Salary</label>

                                <select className="form-select" id="yealry_salary" value={texts.yealry_salary} onChange={handleChange} name="yealry_salary">
                                    <option>Select Salary</option>
                                    <option value="Less than ₹1,00,000">Less than ₹1,00,000</option>
                                    <option value="₹1,00,000 - ₹2,00,000">₹1,00,000 - ₹2,00,000</option>
                                    <option value="₹2,00,000 - ₹4,00,000">₹2,00,000 - ₹4,00,000</option>
                                    <option value="₹4,00,000 - ₹6,00,000">₹4,00,000 - ₹6,00,000</option>
                                    <option value="₹6,00,000 - ₹12,00,000">₹6,00,000 - ₹12,00,000</option>
                                    <option value="₹12,00,000 - ₹20,00,000">₹12,00,000 - ₹20,00,000</option>
                                    <option value="₹20,00,000 - ₹50,00,000">₹20,00,000 - ₹50,00,000</option>
                                    <option value="More than ₹50,00,000">More than ₹50,00,000</option>
                                </select>
                            </Grid>
                            <Grid item xs={6}>
                                <label>Employment</label>
                                <select className="form-select" id="employed" value={texts.employed} onChange={handleChange} name="employed">
                                    <option>Select Employeed</option>
                                    <option value="Employed full-time">Employed full-time</option>
                                    <option value="Employed part-time">Employed part-time</option>
                                    <option value="Self-employed">Self-employed</option>
                                    <option value="Unemployed">Unemployed</option>
                                    <option value="Student">Student</option>
                                    <option value="Retired">Retired</option>
                                    <option value="Other">Other</option>
                                </select>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* <div style={{ width: '100%', paddingRight: '20px' }}>
                        <div className="files">

                            <label htmlFor="profile">
                                <span>Profile Picture</span>
                                <div className="imgContainer">
                                    <img
                                        src={file !== "" ? file : "https://img.freepik.com/premium-vector/young-smiling-man-avatar-man-with-brown-beard-mustache-hair-wearing-yellow-sweater-sweatshirt-3d-vector-people-character-illustration-cartoon-minimal-style_365941-860.jpg?w=2000"}
                                        alt=""
                                    />
                                    <CloudUpload className="icon" />
                                </div>
                            </label>
                            <input
                                type="file"
                                id="profile"
                                style={{ display: "none" }}
                                onChange={(e) => setProfile(e.target.files[0])}
                            />
                        </div>

                        <div className="row from-group">
                            <div className="column">
                                <input
                                    type="text"
                                    value={texts.first_name}
                                    style={{ width: '100%', padding: '10px 0px', marginBottom: '20px', fontSize: '17px', outline: 'none', border: 'none', borderBottom: '1px solid #ccc' }}
                                    name="first_name"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="column">
                                    <input
                                        type="text"
                                        value={texts.email}
                                        style={{ width: '100%', padding: '10px 0px', marginBottom: '20px', fontSize: '17px', outline: 'none', border: 'none', borderBottom: '1px solid #ccc' }}
                                        name="email"
                                        onChange={handleChange}
                                    />
                            </div>
                        </div>



                        <select className="form-select" id="country" value={texts.country} onChange={handleChange} name="country">
                            <option>select country</option>
                            <option value="AF">Afghanistan</option>
                            <option value="AX">Aland Islands</option>
                            <option value="AL">Albania</option>
                            <option value="DZ">Algeria</option>
                            <option value="AS">American Samoa</option>
                            <option value="AD">Andorra</option>
                            <option value="AO">Angola</option>
                            <option value="AI">Anguilla</option>
                            <option value="AQ">Antarctica</option>
                            <option value="AG">Antigua and Barbuda</option>
                            <option value="AR">Argentina</option>
                            <option value="AM">Armenia</option>
                            <option value="AW">Aruba</option>
                            <option value="AU">Australia</option>
                            <option value="AT">Austria</option>
                            <option value="AZ">Azerbaijan</option>
                            <option value="BS">Bahamas</option>
                            <option value="BH">Bahrain</option>
                            <option value="BD">Bangladesh</option>
                            <option value="BB">Barbados</option>
                            <option value="BY">Belarus</option>
                            <option value="BE">Belgium</option>
                            <option value="BZ">Belize</option>
                            <option value="BJ">Benin</option>
                            <option value="BM">Bermuda</option>
                            <option value="BT">Bhutan</option>
                            <option value="BO">Bolivia</option>
                            <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                            <option value="BA">Bosnia and Herzegovina</option>
                            <option value="BW">Botswana</option>
                            <option value="BV">Bouvet Island</option>
                            <option value="BR">Brazil</option>
                            <option value="IO">British Indian Ocean Territory</option>
                            <option value="BN">Brunei Darussalam</option>
                            <option value="BG">Bulgaria</option>
                            <option value="BF">Burkina Faso</option>
                            <option value="BI">Burundi</option>
                            <option value="KH">Cambodia</option>
                            <option value="CM">Cameroon</option>
                            <option value="CA">Canada</option>
                            <option value="CV">Cape Verde</option>
                            <option value="KY">Cayman Islands</option>
                            <option value="CF">Central African Republic</option>
                            <option value="TD">Chad</option>
                            <option value="CL">Chile</option>
                            <option value="CN">China</option>
                            <option value="CX">Christmas Island</option>
                            <option value="CC">Cocos (Keeling) Islands</option>
                            <option value="CO">Colombia</option>
                            <option value="KM">Comoros</option>
                            <option value="CG">Congo</option>
                            <option value="CD">Congo, Democratic Republic of the Congo</option>
                            <option value="CK">Cook Islands</option>
                            <option value="CR">Costa Rica</option>
                            <option value="CI">Cote D'Ivoire</option>
                            <option value="HR">Croatia</option>
                            <option value="CU">Cuba</option>
                            <option value="CW">Curacao</option>
                            <option value="CY">Cyprus</option>
                            <option value="CZ">Czech Republic</option>
                            <option value="DK">Denmark</option>
                            <option value="DJ">Djibouti</option>
                            <option value="DM">Dominica</option>
                            <option value="DO">Dominican Republic</option>
                            <option value="EC">Ecuador</option>
                            <option value="EG">Egypt</option>
                            <option value="SV">El Salvador</option>
                            <option value="GQ">Equatorial Guinea</option>
                            <option value="ER">Eritrea</option>
                            <option value="EE">Estonia</option>
                            <option value="ET">Ethiopia</option>
                            <option value="FK">Falkland Islands (Malvinas)</option>
                            <option value="FO">Faroe Islands</option>
                            <option value="FJ">Fiji</option>
                            <option value="FI">Finland</option>
                            <option value="FR">France</option>
                            <option value="GF">French Guiana</option>
                            <option value="PF">French Polynesia</option>
                            <option value="TF">French Southern Territories</option>
                            <option value="GA">Gabon</option>
                            <option value="GM">Gambia</option>
                            <option value="GE">Georgia</option>
                            <option value="DE">Germany</option>
                            <option value="GH">Ghana</option>
                            <option value="GI">Gibraltar</option>
                            <option value="GR">Greece</option>
                            <option value="GL">Greenland</option>
                            <option value="GD">Grenada</option>
                            <option value="GP">Guadeloupe</option>
                            <option value="GU">Guam</option>
                            <option value="GT">Guatemala</option>
                            <option value="GG">Guernsey</option>
                            <option value="GN">Guinea</option>
                            <option value="GW">Guinea-Bissau</option>
                            <option value="GY">Guyana</option>
                            <option value="HT">Haiti</option>
                            <option value="HM">Heard Island and Mcdonald Islands</option>
                            <option value="VA">Holy See (Vatican City State)</option>
                            <option value="HN">Honduras</option>
                            <option value="HK">Hong Kong</option>
                            <option value="HU">Hungary</option>
                            <option value="IS">Iceland</option>
                            <option value="IN">India</option>
                            <option value="ID">Indonesia</option>
                            <option value="IR">Iran, Islamic Republic of</option>
                            <option value="IQ">Iraq</option>
                            <option value="IE">Ireland</option>
                            <option value="IM">Isle of Man</option>
                            <option value="IL">Israel</option>
                            <option value="IT">Italy</option>
                            <option value="JM">Jamaica</option>
                            <option value="JP">Japan</option>
                            <option value="JE">Jersey</option>
                            <option value="JO">Jordan</option>
                            <option value="KZ">Kazakhstan</option>
                            <option value="KE">Kenya</option>
                            <option value="KI">Kiribati</option>
                            <option value="KP">Korea, Democratic People's Republic of</option>
                            <option value="KR">Korea, Republic of</option>
                            <option value="XK">Kosovo</option>
                            <option value="KW">Kuwait</option>
                            <option value="KG">Kyrgyzstan</option>
                            <option value="LA">Lao People's Democratic Republic</option>
                            <option value="LV">Latvia</option>
                            <option value="LB">Lebanon</option>
                            <option value="LS">Lesotho</option>
                            <option value="LR">Liberia</option>
                            <option value="LY">Libyan Arab Jamahiriya</option>
                            <option value="LI">Liechtenstein</option>
                            <option value="LT">Lithuania</option>
                            <option value="LU">Luxembourg</option>
                            <option value="MO">Macao</option>
                            <option value="MK">Macedonia, the Former Yugoslav Republic of</option>
                            <option value="MG">Madagascar</option>
                            <option value="MW">Malawi</option>
                            <option value="MY">Malaysia</option>
                            <option value="MV">Maldives</option>
                            <option value="ML">Mali</option>
                            <option value="MT">Malta</option>
                            <option value="MH">Marshall Islands</option>
                            <option value="MQ">Martinique</option>
                            <option value="MR">Mauritania</option>
                            <option value="MU">Mauritius</option>
                            <option value="YT">Mayotte</option>
                            <option value="MX">Mexico</option>
                            <option value="FM">Micronesia, Federated States of</option>
                            <option value="MD">Moldova, Republic of</option>
                            <option value="MC">Monaco</option>
                            <option value="MN">Mongolia</option>
                            <option value="ME">Montenegro</option>
                            <option value="MS">Montserrat</option>
                            <option value="MA">Morocco</option>
                            <option value="MZ">Mozambique</option>
                            <option value="MM">Myanmar</option>
                            <option value="NA">Namibia</option>
                            <option value="NR">Nauru</option>
                            <option value="NP">Nepal</option>
                            <option value="NL">Netherlands</option>
                            <option value="AN">Netherlands Antilles</option>
                            <option value="NC">New Caledonia</option>
                            <option value="NZ">New Zealand</option>
                            <option value="NI">Nicaragua</option>
                            <option value="NE">Niger</option>
                            <option value="NG">Nigeria</option>
                            <option value="NU">Niue</option>
                            <option value="NF">Norfolk Island</option>
                            <option value="MP">Northern Mariana Islands</option>
                            <option value="NO">Norway</option>
                            <option value="OM">Oman</option>
                            <option value="PK">Pakistan</option>
                            <option value="PW">Palau</option>
                            <option value="PS">Palestinian Territory, Occupied</option>
                            <option value="PA">Panama</option>
                            <option value="PG">Papua New Guinea</option>
                            <option value="PY">Paraguay</option>
                            <option value="PE">Peru</option>
                            <option value="PH">Philippines</option>
                            <option value="PN">Pitcairn</option>
                            <option value="PL">Poland</option>
                            <option value="PT">Portugal</option>
                            <option value="PR">Puerto Rico</option>
                            <option value="QA">Qatar</option>
                            <option value="RE">Reunion</option>
                            <option value="RO">Romania</option>
                            <option value="RU">Russian Federation</option>
                            <option value="RW">Rwanda</option>
                            <option value="BL">Saint Barthelemy</option>
                            <option value="SH">Saint Helena</option>
                            <option value="KN">Saint Kitts and Nevis</option>
                            <option value="LC">Saint Lucia</option>
                            <option value="MF">Saint Martin</option>
                            <option value="PM">Saint Pierre and Miquelon</option>
                            <option value="VC">Saint Vincent and the Grenadines</option>
                            <option value="WS">Samoa</option>
                            <option value="SM">San Marino</option>
                            <option value="ST">Sao Tome and Principe</option>
                            <option value="SA">Saudi Arabia</option>
                            <option value="SN">Senegal</option>
                            <option value="RS">Serbia</option>
                            <option value="CS">Serbia and Montenegro</option>
                            <option value="SC">Seychelles</option>
                            <option value="SL">Sierra Leone</option>
                            <option value="SG">Singapore</option>
                            <option value="SX">Sint Maarten</option>
                            <option value="SK">Slovakia</option>
                            <option value="SI">Slovenia</option>
                            <option value="SB">Solomon Islands</option>
                            <option value="SO">Somalia</option>
                            <option value="ZA">South Africa</option>
                            <option value="GS">South Georgia and the South Sandwich Islands</option>
                            <option value="SS">South Sudan</option>
                            <option value="ES">Spain</option>
                            <option value="LK">Sri Lanka</option>
                            <option value="SD">Sudan</option>
                            <option value="SR">Suriname</option>
                            <option value="SJ">Svalbard and Jan Mayen</option>
                            <option value="SZ">Swaziland</option>
                            <option value="SE">Sweden</option>
                            <option value="CH">Switzerland</option>
                            <option value="SY">Syrian Arab Republic</option>
                            <option value="TW">Taiwan, Province of China</option>
                            <option value="TJ">Tajikistan</option>
                            <option value="TZ">Tanzania, United Republic of</option>
                            <option value="TH">Thailand</option>
                            <option value="TL">Timor-Leste</option>
                            <option value="TG">Togo</option>
                            <option value="TK">Tokelau</option>
                            <option value="TO">Tonga</option>
                            <option value="TT">Trinidad and Tobago</option>
                            <option value="TN">Tunisia</option>
                            <option value="TR">Turkey</option>
                            <option value="TM">Turkmenistan</option>
                            <option value="TC">Turks and Caicos Islands</option>
                            <option value="TV">Tuvalu</option>
                            <option value="UG">Uganda</option>
                            <option value="UA">Ukraine</option>
                            <option value="AE">United Arab Emirates</option>
                            <option value="GB">United Kingdom</option>
                            <option value="US">United States</option>
                            <option value="UM">United States Minor Outlying Islands</option>
                            <option value="UY">Uruguay</option>
                            <option value="UZ">Uzbekistan</option>
                            <option value="VU">Vanuatu</option>
                            <option value="VE">Venezuela</option>
                            <option value="VN">Viet Nam</option>
                            <option value="VG">Virgin Islands, British</option>
                            <option value="VI">Virgin Islands, U.s.</option>
                            <option value="WF">Wallis and Futuna</option>
                            <option value="EH">Western Sahara</option>
                            <option value="YE">Yemen</option>
                            <option value="ZM">Zambia</option>
                            <option value="ZW">Zimbabwe</option>
                        </select>

                        <input
                            type="text"
                            value={texts.state}
                            placeholder="State"
                            style={{ width: '100%', padding: '10px 0px', marginBottom: '20px', fontSize: '17px', outline: 'none', border: 'none', borderBottom: '1px solid #ccc' }}
                            name="state"
                            onChange={handleChange}
                        />

                        <input
                            type="text"
                            value={texts.city}
                            style={{ width: '100%', padding: '10px 0px', marginBottom: '20px', fontSize: '17px', outline: 'none', border: 'none', borderBottom: '1px solid #ccc' }}
                            name="city"
                            placeholder="City"
                            onChange={handleChange}
                        />

                        <select className="form-select" id="gender" value={texts.gender} onChange={handleChange} name="gender">
                            <option>Select Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Others</option>
                        </select>



                        <input
                            type="text"
                            placeholder="Zip"
                            value={texts.zip}
                            style={{ width: '100%', padding: '10px 0px', marginBottom: '20px', fontSize: '17px', outline: 'none', border: 'none', borderBottom: '1px solid #ccc' }}
                            name="zip"
                            onChange={handleChange}
                        />
                        <label>Date of birth</label>
                        <input
                            type="date"
                            value={texts.date_of_birth}
                            style={{ width: '100%', padding: '10px 0px', marginBottom: '20px', fontSize: '17px', outline: 'none', border: 'none', borderBottom: '1px solid #ccc' }}
                            name="date_of_birth"
                            placeholder="Date Of Birth"
                            onChange={handleChange}
                        />

              

<select className="form-select" id="graduation" value={texts.graduation} onChange={handleChange} name="graduation">
                            <option>Select Graducation</option>
                            <option value="Bachelor of Technology (B.Tech)">Bachelor of Technology (B.Tech)</option>
                            <option value="Bachelor of Engineering (B.E)">Bachelor of Engineering (B.E)</option>
                            <option value="Bachelor of Science (B.Sc)">Bachelor of Science (B.Sc)</option>
                            <option value="Bachelor of Arts (B.A)">Bachelor of Arts (B.A)</option>
                            <option value="Bachelor of Commerce (B.Com)">Bachelor of Commerce (B.Com)</option>
                            <option value="Bachelor of Business Administration (BBA)">Bachelor of Business Administration (BBA)</option>
                            <option value="Bachelor of Fine Arts (BFA)">Bachelor of Fine Arts (BFA)</option>
                            <option value="Bachelor of Architecture (B.Arch)">Bachelor of Architecture (B.Arch)</option>
                            <option value="Bachelor of Pharmacy (B.Pharm)">Bachelor of Pharmacy (B.Pharm)</option>
                            <option value="Bachelor of Laws (LL.B)">Bachelor of Laws (LL.B)</option>
                            <option value="Bachelor of Medicine, Bachelor of Surgery (MBBS)">Bachelor of Medicine, Bachelor of Surgery (MBBS)</option>
                            <option value="Other">Other</option>              
                        </select>

                        <input
                            type="text"
                            value={texts.alternate_contact}
                            style={{ width: '100%', padding: '10px 0px', marginBottom: '20px', fontSize: '17px', outline: 'none', border: 'none', borderBottom: '1px solid #ccc' }}
                            name="alternate_contact"
                            placeholder="Alternate Contact"
                            onChange={handleChange}
                        />

                    


<select className="form-select" id="yealry_salary" value={texts.yealry_salary} onChange={handleChange} name="yealry_salary">
                            <option>Select Salary</option>
                            <option value="Less than ₹1,00,000">Less than ₹1,00,000</option>
                            <option value="₹1,00,000 - ₹2,00,000">₹1,00,000 - ₹2,00,000</option>
                            <option value="₹2,00,000 - ₹4,00,000">₹2,00,000 - ₹4,00,000</option>
                            <option value="₹4,00,000 - ₹6,00,000">₹4,00,000 - ₹6,00,000</option>
                            <option value="₹6,00,000 - ₹12,00,000">₹6,00,000 - ₹12,00,000</option>
                            <option value="₹12,00,000 - ₹20,00,000">₹12,00,000 - ₹20,00,000</option>
                            <option value="₹20,00,000 - ₹50,00,000">₹20,00,000 - ₹50,00,000</option>
                            <option value="More than ₹50,00,000">More than ₹50,00,000</option>

              

                        </select>


                        <select className="form-select" id="employed" value={texts.employed} onChange={handleChange} name="employed">
                            <option>Select Employeed</option>
                            <option value="Employed full-time">Employed full-time</option>
                            <option value="Employed part-time">Employed part-time</option>
                            <option value="Self-employed">Self-employed</option>
                            <option value="Unemployed">Unemployed</option>
                            <option value="Student">Student</option>
                            <option value="Retired">Retired</option>
                            <option value="Other">Other</option>
                        </select>

                    </div> */}

                </div>
                {/* <hr /> */}
                <div className="bottom">

                    <div className="right">
                        <button onClick={handleClick}>Update</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateProfile;
