import React from "react";
import styled from "styled-components";
// Assets
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import PrinterIcon from "../../assets/svg/Services/PrinterIcon";

import Influencer from "../../assets/img/add/influencer1.png";
import Colab from "../../assets/img/add/colab.png";
import Fans from "../../assets/img/add/fan.png";
import Ads from "../../assets/img/add/ads.png";
import Value from "../../assets/img/add/ads.png";
import Auth from "../../assets/img/add/auth.png";
import Exp from "../../assets/img/add/experiance.png";
import Limit from "../../assets/img/add/limit.png";
import Trust from "../../assets/img/add/trust.png";
import Target from "../../assets/img/add/target.png";
import Engage from "../../assets/img/add/engage.png";
import Network from "../../assets/img/add/network.png";







import AddImage1 from "../../assets/img/add/logo.png";


export default function ServiceBox({icon, title, subtitle}) {
  let getIcon;

  switch (icon) {
    case "roller":
      getIcon = <RollerIcon />;
      break;
    case "monitor":
      getIcon = <MonitorIcon />;
      break;
    case "browser":
      getIcon = <BrowserIcon />;
      break;
    case "printer":
      getIcon = <PrinterIcon />;
      break;
    case "influencer":
      getIcon = Influencer ;
      break;
    case "colab":
      getIcon = Colab ;
      break;
    case "fan":
      getIcon = Fans ;
      break;
    case "ads":
      getIcon = Ads;
      break;
    case "trust":
      getIcon = Trust;
      break;
    case "value":
      getIcon = Value;
      break;
    case "limit":
      getIcon = Limit;
      break;
    case "experiance":
      getIcon = Exp;
      break;
    case "auth":
      getIcon = Auth;
      break;
    case "network":
      getIcon = Network;
      break;
    case "engage":
      getIcon = Engage;
      break;
    case "target":
      getIcon = Target;
      break;
    default:
      getIcon = <RollerIcon />;
      break;

      
  }
 
  return (
    <Wrapper className="flex flexColumn">
      <IconStyle>  <img src={getIcon} alt="" /> </IconStyle>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
// const IconStyle = styled.div`
//   @media (max-width: 50px) {
//     height: 40px;
//     weidth: 40px;
//     margin: 0 auto;
//   }
// `;

const IconStyle = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;