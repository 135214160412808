import axios from "axios";


const bearerToken = JSON.parse(localStorage.getItem("user"))?.data.accessToken;

export const makeRequest = axios.create({
  baseURL: "https://myapis.socialunify.com/api/",
  headers: {
    Authorization: `Bearer ${bearerToken}`,
  },
});
