import "./about_us.scss";
import React from 'react'

function About_us() {
  return (
    <div style={{padding: '20px'}}>
      <h2>Contact Us</h2>
      <br></br>

      <h3>We value your feedback and are eager to assist you.</h3>
      <br></br>
<p>If you have any questions, suggestions, or concerns, please don't hesitate to get in touch with us using the information provided below.</p>
      <br></br>
<h3>Customer Support:</h3>
      <br></br>
<p>For any inquiries related to our products or services, or if you require assistance with this service, our dedicated customer support team is here to help. You can reach out to us by:</p>
<p>Email: admin@SocialUnify.com</p>
<p>Phone: +91 88373 22554  </p>
<p>Whatsapp: +91 88373 22554 </p>
      <br></br>
<h3>Business Inquiries:</h3>
      <br></br>
<p>If you have business-related inquiries, partnership opportunities, or media requests, please contact us using the following details:</p>
<p>Email: admin@SocialUnify.com</p>
<p>Phone: +91 88373 22554 </p>
<p>Whatsapp: +91 88373 22554 </p>
      <br></br>



      <h4>Thank you for choosing our website. We look forward to hearing from you and serving you better.</h4>
    </div>
  )
}

export default About_us