import "./about_us.scss";
import React from 'react'

function Security() {
    return (
        <div style={{ padding: '20px' }}>

            <h2>Security and Privacy</h2>
            <br></br>

            <p><strong>How do you protect user privacy and data?</strong></p>
            <p>We take user privacy and data protection seriously. We employ robust security measures to safeguard user information and comply with relevant data protection regulations. We do not share personal information with third parties without explicit consent. For more details, please refer to our Privacy Policy.</p>
            <br></br>
            <p><strong>How secure is my personal information on your platform?</strong></p>
            <p>We utilize industry-standard encryption and security protocols to protect your personal information from unauthorized access or misuse. Our platform undergoes regular security audits and monitoring to ensure the highest level of data security.</p>

        </div>
    )
}

export default Security