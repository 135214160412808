import React, { useEffect, useState } from 'react'
import { makeRequest } from '../../axios';
import { Link, useLocation } from 'react-router-dom';

function UserList() {



  const [data , setdata] = useState([]);

  const location = useLocation();
  const {state} = location;
  let gourp_id = parseInt(useLocation().pathname.split("/")[1]);
  useEffect(() => {


    let    gourp_ids = JSON.parse(localStorage.getItem("user")).data.user.group.group_code;
  
    makeRequest.get("/group/" + gourp_ids).then((res) => {

      const filteredData = res.data.data.group;
  
      // console.log(res);
      setdata(filteredData);
    })


  }, [useLocation().pathname])

  return (
        <div className="home">
            <div className="share" style={{overflowX: 'scroll'}}> 
             <table >
                {/* <thead>
                    <tr>
                    
                        <th>Image</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Action</th>
                    </tr>
                </thead> */}
                <tbody>
                    {
                        data?.users?.length > 0 ? data?.users?.map((item , index) => (
                            <tr>
                            
                            <td><img src={item.image_url ?item.image_url : "https://img.freepik.com/premium-vector/young-smiling-man-avatar-man-with-brown-beard-mustache-hair-wearing-yellow-sweater-sweatshirt-3d-vector-people-character-illustration-cartoon-minimal-style_365941-860.jpg?w=2000" } width={30} height={30} style={{borderRadius: 4}} /></td>
                            <td>{item.first_name}</td>
                            {/* <td>{item.last_name}</td> */}
                            <td><Link to={"/profile/"+item.id}>View</Link></td>
                        </tr>
                        )) : <p>Loading</p>
                    }
                </tbody>
             </table>
            </div>
        </div>
  )
}

export default UserList