import "./share.scss";
import Image from "../../assets/img.png";
import Map from "../../assets/map.png";
import Friend from "../../assets/friend.png";
import { useContext, useState } from "react";
import { AuthContext } from "../../context/authContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { toast } from "react-toastify";
const Share = ({gourp_id}) => {
  const [file, setFile] = useState("");
  const [description, setDesc] = useState("");
  const [title, setTitle] = useState("Dummy title");




  const { currentUser } = useContext(AuthContext);

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (newPost) => {
      return makeRequest.post("/post/create", newPost);
    },
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(["post"]);
  
        setDesc("");
        setFile("");
        toast.success("created successfully..!" , {delay: 500});
      },
      onError: (error) => {
          toast.error(error.response.data.message , {delay: 500});
      }
    }
  );

  const handleClick = async (e) => {
    e.preventDefault();
    let imgUrl = "";
    const formData = new FormData();
    let gourp_ids = JSON.parse(localStorage.getItem("user")).data.user.group.id
    formData.append("group_id", gourp_ids);
    formData.append("title", title);
    formData.append("descriptions", description);
    formData.append("post_image", file);
    mutation.mutate(  formData);
  
  };

  return (
    <div className="share">
      <div className="container">
        <div className="top">
          <div style={{width: '100%' , paddingRight: '20px'}}>
          <input  onChange={(e) => setTitle(e.target.value)} style={{width: '100%' , padding: '10px 0px' , marginBottom: '20px' , fontSize: '17px' , outline: 'none' ,border: 'none', borderBottom: '1px solid #000' , display: 'none'}} type="text" placeholder="Title" name="title" />
            {/* <img src={"/upload/" + currentUser.profilePic} alt="" /> */}
            <textarea
              style={{width: '100%' , height: '200px', border: 'none' , outline: 'none' , resize: 'none'}}
              placeholder={`What's on your mind ${currentUser.data.user.first_name}?`}
              maxLength={200}
              onChange={(e) => setDesc(e.target.value)}
              value={description}
            ></textarea>
          </div>
         
        </div>
        <hr />
        <div className="bottom">
          <div className="left">
            <input
              type="file"
              id="file"
              style={{ display: "none" }}
              onChange={(e) => setFile(e.target.files[0])}
            />
            <label htmlFor="file">
              <div className="item">
                <img src={Image} alt="" />
                <span>{(file) ? "Selected" : "Add Image"}</span>
              </div>
            </label>
         
          </div>
          <div className="right">
            <button onClick={handleClick}>Post</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Share;
