import React, { useEffect, useState } from 'react'
import { makeRequest } from '../../axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';


function JoinGroup()  {

    const { groupid } = useParams(); 
    makeRequest.get("/group/" + groupid + "/join").then((res) => {
        toast.success("Successfully joined..!", { delay: 500 });
        window.location.href = '/group';

    })


    

   
}

export default JoinGroup