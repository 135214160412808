import "./share.scss";
import Image from "../../assets/img.png";
import Map from "../../assets/map.png";
import Friend from "../../assets/friend.png";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/authContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { toast } from "react-toastify";
import { CloudUpload } from "@mui/icons-material";
const UpdateSetting = () => {
    const [file, setFile] = useState(null);
    const [profile, setProfile] = useState(null);
    const [texts, setTexts] = useState({
        old_password: "",
        password: "",
        password_confirmation: ""
    });


    const handleChange = (e) => {
        setTexts((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const { currentUser } = useContext(AuthContext);
    const queryClient = useQueryClient();

    console.log(currentUser);




    const mutation = useMutation(
        (maindata) => {
            return makeRequest.post("/change-password", maindata);
        },
        {
            onSuccess: () => {
                // Invalidate and refetch
                queryClient.invalidateQueries(["user"]);

                setProfile(null);
                setTexts({
        old_password: "",
        password: "",
        password_confirmation: ""
    });
                toast.success("updated successfully..!", { delay: 500 });
            },
            onError: (error) => {
                toast.error(error.response.data.message, { delay: 500 });
            }
        }
    );


    const handleClick = async (e) => {
        e.preventDefault();

        //TODO: find a better way to get image URL

        mutation.mutate(texts);


    };


    return (
        <div className="share">
            <div className="container">
                <div className="top">

                    <div style={{ width: '100%', paddingRight: '20px' }}>
                       

                      <h1 style={{marginBottom: 20}}>Setting</h1>

                        <input
                            type="password"
                            value={texts.old_password}
                            placeholder="Old Password"
                            style={{ width: '100%', padding: '10px 0px', marginBottom: '20px', fontSize: '17px', outline: 'none', border: 'none', borderBottom: '1px solid #ccc' }}
                            name="old_password"
                            onChange={handleChange}
                        />

                        <input
                            type="password"
                            value={texts.password}
                            placeholder="Password"
                            style={{ width: '100%', padding: '10px 0px', marginBottom: '20px', fontSize: '17px', outline: 'none', border: 'none', borderBottom: '1px solid #ccc' }}
                            name="password"
                            onChange={handleChange}
                        />


                        <input
                            type="password"
                            value={texts.password_confirmation}
                            placeholder="Password Confirmation"
                            style={{ width: '100%', padding: '10px 0px', marginBottom: '20px', fontSize: '17px', outline: 'none', border: 'none', borderBottom: '1px solid #ccc' }}
                            name="password_confirmation"
                            onChange={handleChange}
                        />


                    </div>

                </div>
                {/* <hr /> */}
                <div className="bottom">

                    <div className="right">
                        <button onClick={handleClick}>Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateSetting;
