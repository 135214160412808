import { useEffect, useState } from "react";
import "./rightBar.scss";
import { makeRequest } from "../../axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

const RightBar = () => {

  const [data, setdata] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const {state} = location;
  let gourp_id = parseInt(useLocation().pathname.split("/")[1]);
  useEffect(() => {



  let gourp_ids = (state?.group_code)?state.group_code: null;

    if(!gourp_ids){
      gourp_ids = JSON.parse(localStorage.getItem("user")).data.user.group.group_code;
    }


    makeRequest.get("/group/" + gourp_ids).then((res) => {

      const filteredData = res.data.data.group;
  
      // console.log(res);
      setdata(filteredData);
    })


  }, [useLocation().pathname])


  









  return (
    <div className="rightBar forotherpages">
      <div className="container">

        <div className="item">
          <span> </span><span> {data?.users?.length } Members </span>
          {
            data?.users?.length > 0 ? data?.users?.map((item) => (
              <div className="user" onClick={() => navigate("profile/" + item.id)}>
                <div className="userInfo">
                  <img
                    src={item.image_url ? item.image_url : "https://img.freepik.com/premium-vector/young-smiling-man-avatar-man-with-brown-beard-mustache-hair-wearing-yellow-sweater-sweatshirt-3d-vector-people-character-illustration-cartoon-minimal-style_365941-860.jpg?w=2000"}
                    alt=""
                  />
                  <div className="" />
                  <span>{item.first_name + " " + item.last_name}</span>
                </div>
              </div>
            )) : <div>loading</div>
          }


        </div>
      </div>
    </div>
  );
};

export default RightBar;
