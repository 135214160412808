import "./profile.scss";
import FacebookTwoToneIcon from "@mui/icons-material/FacebookTwoTone";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import TwitterIcon from "@mui/icons-material/Twitter";
import PlaceIcon from "@mui/icons-material/Place";
import LanguageIcon from "@mui/icons-material/Language";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Posts from "../../components/posts/Posts";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { useLocation } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../context/authContext";
import Update from "../../components/update/Update";
import { useState } from "react";

const Profile = () => {
  const [openUpdate, setOpenUpdate] = useState(false);
  const { currentUser } = useContext(AuthContext);

  const userId = parseInt(useLocation().pathname.split("/")[2]);


  const [data , setdata] = useState([]);

  useEffect(() => {
    makeRequest.get("/user/"+userId).then((res) => {
      setdata(res.data.data.user);
    })
  },[openUpdate, userId])





  return (
    <div className="profile">
   
        <>
          <div className="images">
            <img src={data.image_url ? data.image_url :"https://img.freepik.com/premium-vector/young-smiling-man-avatar-man-with-brown-beard-mustache-hair-wearing-yellow-sweater-sweatshirt-3d-vector-people-character-illustration-cartoon-minimal-style_365941-860.jpg?w=2000"} alt="" className="profilePic" />
          </div>
          <div className="profileContainer">
            <div className="uInfo">
              {/* <div className="left">
                <a href="http://facebook.com">
                  <FacebookTwoToneIcon fontSize="large" />
                </a>
                <a href="http://facebook.com">
                  <InstagramIcon fontSize="large" />
                </a>
                <a href="http://facebook.com">
                  <TwitterIcon fontSize="large" />
                </a>
                <a href="http://facebook.com">
                  <LinkedInIcon fontSize="large" />
                </a>
                <a href="http://facebook.com">
                  <PinterestIcon fontSize="large" />
                </a>
              </div> */}
              <div >
                <span style={{marginBottom: '15px' , display: 'block'}}>First Name: {data.first_name}</span>
                <span style={{marginBottom: '15px' , display: 'block'}}>Last Name: {data.last_name}</span>

                <div className="info">
                
                  <div className="item" style={{marginBottom: '15px'}}>
                    {/* <PlaceIcon /> */}
                    <span>Gender: {data.gender}</span>
                  </div>
                  <div className="item" style={{marginBottom: '15px'}}>
                    {/* <PlaceIcon /> */}
                    <span>Zip: {data.zip}</span>
                  </div>
                 

                  
                </div>
               
           
                
              </div>
              <div className="right">
              
              </div>
            </div>
          </div>
        </>
    
    
    </div>
  );
};

export default Profile;
