import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./register.scss";
import axios from "axios";
import { toast } from "react-toastify";
import { makeRequest } from "../../axios";


const Register = () => {
  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    password: "",
    email: "",
  });
  const [err, setErr] = useState(null);

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const navigate = useNavigate();

  const handleOpenGoogle = () => {
    makeRequest.get("/google-login").then(res => {
      window.location = res.data.data.url;
    })
  }
  
  const handleClick = async (e) => {
    e.preventDefault();

   axios.post("https://myapis.socialunify.com/api/register", inputs).then(res => {
    toast.success("Successfully Registerd..!" , {delay: 500});
    navigate("/");
   }).catch(error => {
    if (error.response) {
      toast.error(error.response.data.message  , {delay: 500});
    }
  });

  };

  console.log(err);

  return (
    <div className="register">
      <div className="card">
        <div className="left">
          <h1 style={{fontSize: "45px"}}>Socialunify.</h1>
          <p>
            Platform brings together social media influencers and their fans in one place. 
          </p>
          <span>Do you have an account?</span>
          <Link to="/login">
            <button>Login</button>
          </Link>
        </div>
        <div className="right">
          <h1>Register</h1>
          <form>
            <input
              type="text"
              placeholder="First Name"
              name="first_name"
              onChange={handleChange}
              autocomplete="off"
            />
            <input
              type="text"
              placeholder="last Name"
              name="last_name"
              onChange={handleChange}
              autocomplete="off"
            />
            <input
              type="email"
              placeholder="Email"
              name="email"
              onChange={handleChange}
              autocomplete="off"
            />
            <input
              type="password"
              placeholder="Password"
              name="password"
              onChange={handleChange}
              autocomplete="off"
            />
            {err && err}
            <button onClick={handleClick}>Register</button>
            {/* <Link onClick={handleOpenGoogle}>
              <button>Google Login</button>
            </Link> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
