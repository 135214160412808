import Stories from "../../components/stories/Stories"
import Posts from "../../components/posts/Posts"
import Share from "../../components/share/Share"
import "./home.scss"
import { useLocation } from "react-router-dom"

const Home = () => {



  const location = useLocation();
  const {state} = location;
  let gourp_id = (state?.group_code)?state.group_code: null;

    if(!gourp_id){
      gourp_id = JSON.parse(localStorage.getItem("user")).data.user.group.group_code;
    }



  return (
    <div className="home">
      {
        (gourp_id === JSON.parse(localStorage.getItem("user")).data.user.group.group_code) && (
          <Share gourp_id={gourp_id} />
        )
      }

      <Posts gourp_id={gourp_id} />
    </div>
  )
}

export default Home