import { margin } from "@mui/system";
import React from "react";
import styled from "styled-components";
// Assets

import Logo from "../../assets/img/add/logo.png";

export default function Contact() {
  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Let's get in touch</h1>
            <p className="font13">
              Do not hesitate to connect with us, we will revert your enquire asap.
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form>
                <span className="font13">Socialunify</span><br></br>
                <span className="font13">Email: admin@socialunify.com</span><br></br>
                <span className="font13">Mobile Number : +91 88373 22554</span><br></br>
                </Form>
              
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
             
              
                <div>
                  <img src={Logo} alt="office" className="radius6" style={{ width: "50%" }} />
                </div>
            
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
  span {
    font-size: 20px;
  }
`;










