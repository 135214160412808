import React from 'react'
import UpdateProfile from '../../components/updateProfile/updateProfile'
import UpdateSetting from '../../components/updateSetting/updateSetting'

function UpdateSettings() {
  return (    <div className="home">
    <UpdateSetting  />
    </div>
  )
}

export default UpdateSettings