import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import "./login.scss";
import { toast } from "react-toastify";
import axios from "axios";
import { makeRequest } from "../../axios";

const VerifyPassword = () => {
    const [inputs, setInputs] = useState({
        email: "",
        token: "",
        password: "",
        password_confirmation: ""

    });
    const [err, setErr] = useState(null);

    const navigate = useNavigate();
    //const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const handleChange = (e) => {
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };
    const { currentUser, login, setCurrentUser } = useContext(AuthContext);

    const handleLogin = async (e) => {
        e.preventDefault();


        axios.post("https://myapis.socialunify.com/api/reset_password", inputs).then(res => {
            console.log(res.data);
            toast.success("Password reset successfully!", { delay: 500 });

            navigate('/login');
        }).catch(error => {
            if (error.response) {
                toast.error(error.response.data.message, { delay: 500 });
            }
        });




    };


    useEffect(() => {
        // Get the query parameters from the URL
        const queryParams = new URLSearchParams(window.location.search);

        // Access the 'accessToken' and 'user' parameters from the query
        if (queryParams.get('accessToken')) {
            const accessToken = queryParams.get('accessToken');
            const userJsonString = queryParams.get('user');

            // Parse the 'user' parameter as a JSON object
            const userObject = JSON.parse(userJsonString);

            // Now you have access to the 'accessToken' and 'user' object in your component
            console.log('accessToken:', accessToken);
            console.log('userObject:', userObject);

            const logindate = {
                data: { user: userObject, accessToken: accessToken },
                message: "login successfully...!",
                status: 200
            }
            login(logindate);
        }
    }, [])


    const handleOpenGoogle = () => {
        makeRequest.get("/google-login").then(res => {
            window.location = res.data.data.url;
        })
    }
    // if (currentUser) {
    //   return <Navigate to="/" />;
    // }
    return (

        <div className="register">
            <div className="card">
                <div className="left">
                    <h1 style={{ fontSize: "45px" }}>Socialunify.</h1>
                    <p>
                        Platform brings together social media influencers and their fans in one place. It provides a
                        centralized space for fans to connect with their favorite influencers, stay updated on their latest content,
                        and engage with other like-minded fans.
                    </p>
                    <span>Don't you have an account?</span>
                    <Link to="/register">
                        <button>Register</button>
                    </Link>
                    <Link onClick={handleOpenGoogle}>
                        <button>Google Login</button>
                    </Link>
                </div>
                <div className="right">
                    <h1>Reset Password</h1>
                    <form>
                        <input
                            type="email"
                            placeholder="email"
                            name="email"
                            onChange={handleChange}
                            autocomplete="off"
                        />
                        <input
                            type="text"
                            placeholder="Token"
                            name="token"
                            onChange={handleChange}
                            autocomplete="off"
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            name="password"
                            onChange={handleChange}
                            autocomplete="off"
                        />
                        <input
                            type="password"
                            placeholder="Confirm Password"
                            name="password_confirmation"
                            onChange={handleChange}
                            autocomplete="off"
                        />
                        {err && err}
                        <button onClick={handleLogin}>Login</button>
                    </form>
                </div>
            </div>
        </div>


    );
};

export default VerifyPassword;
