import React, { useEffect, useState } from 'react'
import { makeRequest } from '../../axios';
import { Link } from 'react-router-dom';

function GroupList() {



  const [data , setdata] = useState([]);

  useEffect(() => {
    makeRequest.get("/group/user-groups").then((res) => {
      console.log(res.data);
     let user_id = JSON.parse(localStorage.getItem("user")).data.user.id;
    //  setdata(array);
    setdata(res.data.data.groups);
    })
  },[])

  return (
        <div className="home">
            <div className="share" style={{overflowX: 'scroll'}}> 
             <table >
                {/* <thead>
                    <tr>
                  
                        <th>Group Name</th>
                        <th>Action</th>
                    </tr>
                </thead> */}
                <tbody>
                    {
                        data?.length > 0 ? data.map((item , index) => (
                            <tr>
                                {console.log(item)}
                                <td><img src={item.group.user.image_url ? item.group.user.image_url : "https://img.freepik.com/premium-vector/young-smiling-man-avatar-man-with-brown-beard-mustache-hair-wearing-yellow-sweater-sweatshirt-3d-vector-people-character-illustration-cartoon-minimal-style_365941-860.jpg?w=2000"} width={30} height={30} style={{ borderRadius: 4 }} /></td>
                                <td>{item.group.group_code}</td>
                            <td><Link to={"/"}  state={{group_code:item.group.group_code}} >View</Link></td>
                        </tr>
                        )) : <p>Loading</p>
                    }
                </tbody>
             </table>
            </div>
        </div>
  )
}

export default GroupList