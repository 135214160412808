import { useContext, useEffect, useState } from "react";
import "./comments.scss";
import { AuthContext } from "../../context/authContext";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import moment from "moment";
import { toast } from "react-toastify";

const Comments = ({ postId }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const [comment, setDesc] = useState("");
  const { currentUser } = useContext(AuthContext);

  const [data , setdata] = useState([]);
  useEffect(() => {
    makeRequest.get("/comment?post_id=" + postId).then((res) => {
      // return res.data[0];
      // console.log(res.data.data.comments);
      setdata(res.data.data.comments);
    })
  },[])





  const queryClient = useQueryClient();

  const mutation = useMutation(
    (newComment) => {
      return makeRequest.post("/comment/create", newComment);
    },
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(["comment"]);
        toast.success("created successfully..!" , {delay: 500});
        makeRequest.get("/comment?post_id=" + postId).then((res) => {

          setdata(res.data.data.comments);
        })
      },
    }
  );

  const handleClick = async (e) => {
    e.preventDefault();
    let post_id = postId;
    mutation.mutate({ comment, post_id });
    setDesc("");
  };

  return (
    <div className="comments">
      <div className="write">
        <img src={user.data.user.image_url ? user.data.user.image_url : "https://img.freepik.com/premium-vector/young-smiling-man-avatar-man-with-brown-beard-mustache-hair-wearing-yellow-sweater-sweatshirt-3d-vector-people-character-illustration-cartoon-minimal-style_365941-860.jpg?w=2000"} alt="" />
        <input
          type="text"
          placeholder="write a comment"
          value={comment}
          onChange={(e) => setDesc(e.target.value)}
        />
        <button onClick={handleClick}>Send</button>
      </div>
      {
         data.length>0?data.map((comment) => (
            <div className="comment" key={comment.id}>
              <div >
                <p>{comment.user.first_name} : </p>
              </div>
              <div className="info">
                <p>{comment.comment}</p>
              </div>
              <span className="date">
                {moment(comment.created_at).fromNow()}
              </span>
            </div> 
        )): "loading"}
    </div>
  );
};

export default Comments;
