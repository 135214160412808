import Index from "./pages/Index/index";

import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import ForgotPassword from "./pages/forgot_password/ForgotPassword";
import VerifyPassword from "./pages/verify_password/VerifyPassword";


import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
} from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import LeftBar from "./components/leftBar/LeftBar";
import RightBar from "./components/rightBar/RightBar";
import Home from "./pages/home/Home";
import Profile from "./pages/profile/Profile";
import "./style.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { AuthContext } from "./context/authContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import UpdateProfile from "./pages/updateprofile/UpdateProfile";
import About_us from "./pages/about_us/about_us";
import Faq from "./pages/faq/faq";
import Security from "./pages/faq/security";



import UpdateSettings from "./pages/updatesetting/UpdateSetting";
import UserList from "./pages/user/userList";
import GroupList from "./pages/Group/list";
import JoinGroupList from "./pages/joinGroup/JoinGroup";
import JoinGroup from "./pages/joinGroup/Join";



function App() {
  const { currentUser } = useContext(AuthContext);

  const { darkMode } = useContext(DarkModeContext);

  const queryClient = new QueryClient();

  const Layout = () => {
    return (
      <QueryClientProvider client={queryClient}>
        <div className={`theme-${darkMode ? "dark" : "light"}`}>
          <Navbar />
          <div className="reponsive-mobile home-rep">
            <LeftBar />
            <div style={{ flex: 6 }}>
              <Outlet />
            </div>
            <RightBar />
          </div>
        </div>
      </QueryClientProvider>
    );
  };

  const ProtectedRoute = ({ children }) => {
    if (!currentUser) {
      return <Navigate to="/home" />;
    }

    return children;
  };

  const LoggedInRoute = ({ children }) => {
    if (currentUser) {
      return <Navigate to="/" />;
    }

    return children;
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/profile/:id",
          element: <Profile />,
        },
        {
          
          path: "/updateProfile",
          element: <UpdateProfile />,
        },

        {
          
          path: "/joinGroup",
          element: <JoinGroupList />,
        },
        {

          path: "/join/:groupid",
          element: <JoinGroup />,
        },
        {
          path: "/about",
          element: <About_us />,
        },
        {
          path: "/faq",
          element: <Faq />,
        },
        {
          path: "/security",
          element: <Security />,
        },
        {
          path: "/setting",
          element: <UpdateSettings />,
        },
        {
          path: "/user",
          element: <UserList />,
        },
        {
          path: "/group",
          element: <GroupList />,
        },
        
      ],
    },
    {
      path: "/home",
      element: <Index />,
    },
    {
      path: "/login",
      element: (
        <LoggedInRoute>
          <Login />
        </LoggedInRoute>
      ),
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/resetPassword",
      element: <ResetPassword />,
    },
    {
      path: "/forgotpassword",
      element: <ForgotPassword />,
    },
    {
      path: "/verifyPassword",
      element: <VerifyPassword />,
    },
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
