// import React from 'react';
// import "./index.scss";
// import Navbar from 'react-bootstrap/Navbar';
// import Nav from 'react-bootstrap/Nav';

// import 'bootstrap/dist/css/bootstrap.min.css';


// function Index() {
//         return (
            
//                     <body class="heroMain">
//                         <header>
//                     <h2><img
//                         src="https://www.socialunify.com/upload/o.png" width="40" height="40" className="d-inline-block align-top" alt="Logo"/> Socialunify</h2>
//                             <nav>
//                         <li><a href="https://www.socialunify.com/register" className="navLink">Signup</a></li>
//                         <li><a href="https://www.socialunify.com/login" className="navLink">Login</a></li>
//                             </nav>
//                         </header>

//                         <section class="hero">
//                             <div class="background-image"></div>
//                             <div class="hero-content-area">
//                                 <h1>Socialunify</h1>
//                         <h3>Platform brings together social media influencers and their fans in one place</h3>
//                         <a href="https://www.socialunify.com/register" class="btn">Get Started</a>
//                             </div>
//                         </section>


//                         <footer>
                            
//                     <p>&copy; 2023 Socialunify. All rights reserved.</p>
//                         </footer>
//                 </body>
//         );
// }


// export default Index

import React from "react";
// Sections
import TopNavbar from "../../components/Nav/TopNavbar";
import Header from "../../components/Sections/Header";
import Services from "../../components/Sections/Services";
import Projects from "../../components/Sections/Projects";

import Blog from "../../components/Sections/Blog";
import Pricing from "../../components/Sections/Pricing";
import Contact from "../../components/Sections/Contact";
import Cont from "../../components/Sections/Cont";

import Footer from "../../components/Sections/Footer"

function Index() {
    return (
        <>
            <TopNavbar />
            <Header />
            <Services />
            <Projects />
            <Blog />
            <Cont />

            <Footer />
        </>
    );
}


export default Index