import "./leftBar.scss";
import Friends from "../../assets/1.png";
import Groups from "../../assets/2.png";
import Events from "../../assets/6.png";
import Gaming from "../../assets/7.png";

import Messages from "../../assets/10.png";
import Tutorials from "../../assets/11.png";
import Courses from "../../assets/12.png";
import Fund from "../../assets/13.png";
import { AuthContext } from "../../context/authContext";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

const LeftBar = () => {

  const { currentUser , logout } = useContext(AuthContext);

  const navigate = useNavigate();

  const hanleClick = async () => {


    try {
      await logout();
      navigate("/login");
    } catch (err) {
      console.log("Logout failed with error:" + err);
    }
  };

  return (
    <div className="leftBar">
      <div className="container">
        <div className="menu">
          <div className="user">
          </div>
          <Link to="/" className="item">
            <img src={Events} alt="" />
            <span>Dashboard</span>
          </Link>
          <Link to="/user" className="item">
            <img src={Friends} alt="" />
            <span>Users</span>
          </Link>
          <Link to="/group" className="item">
            <img src={Groups} alt="" />
            <span>My Groups</span>
          </Link>

          <Link to="/joinGroup" className="item">
            <img src={Gaming} alt="" />
            <span>Join Group</span>
          </Link>
      
        </div>
        <hr />
        {/* <div className="menu">
          <span>Your shortcuts</span>
          <div className="item">
            <img src={Events} alt="" />
            <span>Events</span>
          </div>
          <div className="item">
            <img src={Gaming} alt="" />
            <span>Gaming</span>
          </div>
          <div className="item">
            <img src={Gallery} alt="" />
            <span>Gallery</span>
          </div>
          <div className="item">
            <img src={Videos} alt="" />
            <span>Videos</span>
          </div>
          <div className="item">
            <img src={Messages} alt="" />
            <span>Messages</span>
          </div>
        </div> */}
        {/* <hr /> */}
        <div className="menu">
          <span>Others</span>
          <Link to={"/setting"} className="item">
            <img src={Fund} alt="" />
            <span>Setting</span>
          </Link>
          <Link to={"/updateProfile"} className="item">
            <img src={Messages} alt="" />
            <span>Profile</span>
          </Link>
          <Link to="/about" className="item">
            <img src={Tutorials} alt="" />
            <span>Contact us</span>
          </Link>
          <Link to="/security" className="item">
            <img src={Events} alt="" />
            <span>Security</span>
          </Link>
          
          <Link to="/faq" className="item">
              <img src={Gaming} alt="" />
              <span>FAQ's</span>
            </Link>
          <div onClick={() => hanleClick()} className="item">
            <img src={Courses} alt="" />
            <span>Logout</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftBar;
