import Post from "../post/Post";
import "./posts.scss";
import { useQuery } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { useLocation } from "react-router-dom";

const Posts = ({ userId, gourp_id }) => {

  const location = useLocation();
  const { state } = location;


  let gourp_ids = (state?.group_code) ? state.group_code : null;

  if (!gourp_ids) {
    gourp_ids = JSON.parse(localStorage.getItem("user")).data.user.group.group_code;
  }



  const { isLoading, error, data } = useQuery(["post"], () =>
    makeRequest.get("/group/" + gourp_ids).then((res) => {

      const filteredData = res.data.data.group;

      // console.log(res);
      return filteredData;
    })
  );


  // console.log(data);
  return (
    <div className="posts">
      {data?.posts?.length > 0 ? data?.posts?.map((post) => <Post post={post} key={post.id} />) : "Loading"}
    </div>
  );
};

export default Posts;
