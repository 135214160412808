import "./navbar.scss";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { DarkModeContext } from "../../context/darkModeContext";
import { AuthContext } from "../../context/authContext";

import { useEffect , useState} from 'react';
import { useLocation } from 'react-router-dom';

const Navbar = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const { toggle, darkMode } = useContext(DarkModeContext);
  const { currentUser, logout } = useContext(AuthContext);
  // console.log("User data is ", user);
  // console.log("User data is ", user.data.user.group.group_code);
  // console.log("User data is ", user.data.user.first_name);
  // console.log("User data is ", user.data.user.group);




  const navigate = useNavigate();

  const history = useLocation();
  let gourp_id = parseInt(useLocation().pathname.split("/")[1]);
  useEffect(() => {
    // This effect will run every time the router changes
    const elements = document.getElementsByClassName("leftBar");

    // Step 2: Loop through the elements and toggle the class
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("open");
    }

    // console.log(history.pathname);
    console.log(history.pathname , "/"+gourp_id);
    if(!gourp_id){
      gourp_id="";
    }

   

    if(history.pathname === "/"+gourp_id){
          // This effect will run every time the router changes
    const elements = document.getElementsByClassName("rightBar");
      console.log("calling");
    // Step 2: Loop through the elements and toggle the class
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("forotherpages");
    }
    }else{
               // This effect will run every time the router changes
    const elements = document.getElementsByClassName("rightBar");

    // Step 2: Loop through the elements and toggle the class
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add("forotherpages");
    }
    }

    // Optionally, you can clean up the effect to avoid memory leaks
    return () => {
      // Clean up code (if needed)
    };
  }, [history.pathname]); // Only re-run the effect if the pathname changes


  const hanleClick = async (e) => {
    e.preventDefault();

    try {
      await logout();
      navigate("/login");
    } catch (err) {
      console.log("Logout failed with error:" + err);
    }
  };
  const groupLink = 'https://www.socialunify.com/join/' + user.data.user.group.group_code;


  const [isCopied, setIsCopied] = useState(false);
  const textToCopy = groupLink;

  const handleCopyClick = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2500);
    });
  };



  const handleOpenNav = () => {
// Step 1: Get the elements with the class "responsive-mobile"
const elements = document.getElementsByClassName("leftBar");

// Step 2: Loop through the elements and toggle the class
for (let i = 0; i < elements.length; i++) {
  elements[i].classList.toggle("open");
}
  }
  return (
    <div className="navbar">
      <div className="left">
        <Link to="/" style={{ textDecoration: "none" , display: 'flex' , alignItems: 'center' }}>
          <img src="/upload/o.png" width={30} height={30} style={{borderRadius: 50}}/>
          <span style={{marginLeft: 10}}>Socialunify
</span>
        </Link>
     
     
        <GridViewOutlinedIcon onClick={() => handleOpenNav()} />
      
        <div class="copy-text">
       
          <input type="text" class="text" value={groupLink} />
          <span>      </span>
          <button className="btn btn-primary " onClick={handleCopyClick} >
            {isCopied ? 'Copied!' : 'Copy Group link'}
          </button>
        </div>

       
        

      </div>
      <div className="right">
     
        <div className="user">
          <img src={user.data.user.image_url ? user.data.user.image_url : "https://img.freepik.com/premium-vector/young-smiling-man-avatar-man-with-brown-beard-mustache-hair-wearing-yellow-sweater-sweatshirt-3d-vector-people-character-illustration-cartoon-minimal-style_365941-860.jpg?w=2000"} alt="" />
          <span>{user.data.user.first_name}</span>
        </div>
        <LogoutIcon onClick={hanleClick} />
      </div>
    </div>
  );
};

export default Navbar;
