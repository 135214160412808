import React, { useEffect, useState } from 'react'
import { makeRequest } from '../../axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

function JoinGroupList() {

    const [search , setSearch] = useState("");

  const [data , setdata] = useState([]);

  useEffect(() => {
    makeRequest.get("/group?search="+search).then((res) => {
        setdata(res.data.data.groups);
    })
  },[search])

  const joinTheGroup = (group_code , user_id) => {
    makeRequest.get("/group/"+group_code+"/join").then((res) => {
        toast.success("Successfully joined..!" , {delay: 500});
    })
  }

  return (
        <div className="home">
            <div className="share" style={{overflowX: 'scroll'}}> 
            <div className='search-box'>
                <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Search' style={{margin: 30,padding: 10, border: '1px solid #ccc', borderRadius: 6 }} />
            </div>
             <table >
                {/* <thead>
                    <tr>
                        <th>Profile</th>

                        <th>Group Code</th>
                        <th>Group Name</th>

                        <th>Action</th>
                    </tr>
                </thead> */}
                <tbody>
                    {
                        data?.length > 0 ? data.map((item , index) => (
                            <tr>
                            <td><img src={item.user.image_url ? item.user.image_url : "https://img.freepik.com/premium-vector/young-smiling-man-avatar-man-with-brown-beard-mustache-hair-wearing-yellow-sweater-sweatshirt-3d-vector-people-character-illustration-cartoon-minimal-style_365941-860.jpg?w=2000"} width={30} height={30} style={{ borderRadius: 4 }} /></td>
                            <td>{item.group_code}</td>
                            <td>{item.name}</td>
                            <td><Link onClick={() => joinTheGroup(item.group_code , item.user.id)}> Join Group</Link></td>
                        </tr>
                        )) : <p>Loading</p>
                    }
                </tbody>
             </table>
            </div>
        </div>
  )
}

export default JoinGroupList